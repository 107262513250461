import { flow } from 'lodash';

import { StatCards } from './StatCards';
import { withState } from './StatCards.withState';

export const StatCardsContainer = flow(
    //
    () => StatCards,
    withState()
)();
