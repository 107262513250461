import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { HOC } from '../../../utils';
import { SearchProps } from './Search.type';

type InjectedProps = Pick<
    SearchProps,
    'value' | 'handleInput' | 'handleSubmit'
>;
type OwnProps = Pick<SearchProps, 'placeholder'>;

export const withState =
    (): HOC<InjectedProps, OwnProps> => (BaseComponent) => (props) => {
        const [value, setValue] = useState<SearchProps['value']>('');
        const router = useRouter();

        const handleSubmit = () => {
            router.push(`/purchases?search=${value}`);
        };

        const injectedProps: InjectedProps = {
            value,
            handleInput: setValue,
            handleSubmit,
        };

        return <BaseComponent {...(props as any)} {...injectedProps} />;
    };
