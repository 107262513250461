import { flow } from 'lodash';

import { Search } from './Search';
import { withState } from './Search.withState';

export const SearchContainer = flow(
    //
    () => Search,
    withState()
)();
