import Link from 'next/link';
import React, { useEffect, useRef } from 'react';
import styles from './Search.module.scss';

import { SearchProps } from './Search.type';

export const Search: React.FC<SearchProps> = (props) => {
    const { placeholder, value, handleInput, handleSubmit } = props;
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        const handlePressEnter = (e: { keyCode: number }) => {
            if (e.keyCode === 13) {
                handleSubmit();
            }
        };
        if (inputRef && inputRef.current) {
            const current = inputRef.current;
            current?.addEventListener('keydown', handlePressEnter, false);
            return function cleanup() {
                current?.removeEventListener(
                    'keydown',
                    handlePressEnter,
                    false
                );
            };
        }
    }, [handleSubmit]);

    return (
        <div className={styles.container}>
            <div className={styles.search}>
                <input
                    name="search"
                    value={value}
                    className={styles.input}
                    placeholder={placeholder}
                    onChange={(e) => handleInput(e.currentTarget.value)}
                    ref={inputRef}
                />
                <button onClick={handleSubmit} className={styles.button}>
                    <img src="/assets/search/search.svg" alt="search" />
                </button>
            </div>
            <Link href="/purchases">
                <a className={styles.extended}> Расширенный поиск</a>
            </Link>
        </div>
    );
};
