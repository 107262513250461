import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import type { NextPage } from 'next';
import { cachedFetchMainData } from '../api/fetchMainData';
import { cachedFetchSectionOrgs } from '../api/fetchSectionOrgs';
import { SectionsOrgResponse } from '../api/fetchSectionOrgs';
import { useResetBreadcrumbs } from '../components/_shared/Breadcrumbs/BreadcrumbsContext';
import { Main } from '../components/Main';

export type MainPageProps = {
    counts: Awaited<ReturnType<typeof cachedFetchMainData>>['counts'];
    news: Awaited<ReturnType<typeof cachedFetchMainData>>['news'];
    orgSections: SectionsOrgResponse;
};

const MainPage: NextPage<MainPageProps> = ({ counts, orgSections, news }) => {
    useResetBreadcrumbs();
    return <Main counts={counts} news={news} orgSections={orgSections} />;
};

export default MainPage;

export const getServerSideProps: GetServerSideProps = async (
    context: GetServerSidePropsContext
) => {
    context.res.setHeader(
        'Cache-Control',
        'public, s-maxage=10, stale-while-revalidate=59'
    );

    const orgSections = await cachedFetchSectionOrgs({
        limit: 20,
        hasLogo: true,
        minWeight: 10,
    });
    const mainData = await cachedFetchMainData();

    return {
        props: {
            counts: mainData.counts,
            news: mainData.news,
            orgSections,
        },
    };
};
