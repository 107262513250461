import React from 'react';
import { StatCard } from '../StatCard';

import styles from './StatCards.module.scss';
import { StatCardsProps } from './StatCards.type';

export const StatCards: React.FC<StatCardsProps> = ({ data, description }) => (
    <div className={styles.container}>
        {data.map((item, key) => (
            <div className={styles.item} key={key}>
                <StatCard {...item} />
            </div>
        ))}
        <div className={styles.description}>{description}</div>
    </div>
);
