import Link from 'next/link';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { Sphere } from '../_shared';
import { rootLinks } from '../Links';
import { Companies } from './Companies/Companies';
import { Counters } from './Counters/Counters';
import styles from './Main.module.scss';
import { MainImage } from './MainImage';
import { NewsCards } from './NewsCards/NewsCards';
import { Profits } from './Profits';
import { Search } from './Search';
import { StatCards } from './StatCards';
import { ChevronRightIcon } from '../Icons';
import { MainPageProps } from '../../pages';

export type MainProps = MainPageProps;

export const Main: FC<MainProps> = ({ counts, news, orgSections }) => {
    const isBrowser = typeof window !== 'undefined';
    const [windowWidth, setWindowWidth] = useState(
        isBrowser ? window.innerWidth : 1120
    );

    const handleResize = useCallback(() => {
        setWindowWidth(isBrowser ? window.innerWidth : 1120);
    }, [isBrowser]);
    useEffect(() => {
        return window.addEventListener('resize', handleResize);
    }, [handleResize]);
    const isSphereExists = useMemo(() => windowWidth > 484, [windowWidth]);
    const isCompaniesExist = useMemo(() => windowWidth > 1120, [windowWidth]);
    return (
        <>
            <div className={styles.container}>
                <div className={styles.bg}>
                    <MainImage />
                    <div className={styles.content}>
                        <div className={styles.title}>
                            <h1>Единая система торговых процедур</h1>
                        </div>
                        <div className={styles.search}>
                            <Search placeholder="Введите номер или наименование закупки, ИНН, название заказчика" />
                        </div>
                        <div className={styles.counter}>
                            <Counters data={counts} />
                        </div>
                        {!isSphereExists && (
                            <div className={styles.blueSphere} />
                        )}
                    </div>
                </div>
                <div className={styles.info}>
                    <div className={styles.news}>
                        <NewsCards news={news} />
                    </div>
                    <div className={styles.centerBlock}>
                        {!isSphereExists && (
                            <div className={styles.greenSphere} />
                        )}
                        {isSphereExists && (
                            <Sphere
                                color={'purple'}
                                zIndex={1}
                                step={0.01}
                                maxMove={30}
                            />
                        )}
                        {isSphereExists && (
                            <Sphere
                                color={'blue'}
                                zIndex={2}
                                step={0.009}
                                maxMove={20}
                                opacity={0.5}
                            />
                        )}
                        {isSphereExists && (
                            <Sphere
                                color={'red'}
                                zIndex={3}
                                step={0.02}
                                maxMove={40}
                                opacity={0.8}
                            />
                        )}
                        {!isSphereExists && (
                            <div className={styles.redSphere} />
                        )}
                        <div className={styles.centerBlockItem}>
                            <Profits />
                        </div>
                        <div className={styles.centerBlockItem}>
                            <StatCards />
                        </div>
                    </div>
                    {isCompaniesExist && (
                        <div className={styles.companies}>
                            <Companies orgSections={orgSections} />
                        </div>
                    )}
                    <div className={styles.about}>
                        <h2>О estp.ru</h2>
                        <p>
                            Универсальная электронная торговая площадка ESTP.RU
                            обеспечивает прозрачное и эффективное проведение
                            закупок (тендеров), осуществляемых по Закону РФ №
                            223-ФЗ, коммерческих закупок, а также быстрых
                            закупок через электронный магазин.
                        </p>
                        <p>
                            С 2014 года мы эффективно помогаем нашим клиентам
                            найти заказчиков и поставщиков на территории всей
                            Российской Федерации, по всем направлениям, в том
                            числе показывая высокую результативность проведения
                            строительных тендеров, закупок на поставку
                            медицинских изделий, продуктов питания, оказания
                            различного вида услуг и поставку других товаров.
                        </p>
                        <p>
                            Работать с нами удобно и легко. Мы заботимся о
                            клиентах и экономим Ваши деньги.
                        </p>
                        <div className={styles.aboutFooter}>
                            <Link href={rootLinks.info.url}>
                                <a className={styles.link}>
                                    <span>подробнее</span>
                                    <ChevronRightIcon />
                                </a>
                            </Link>
                        </div>
                        {isSphereExists && (
                            <Sphere
                                color={'green'}
                                zIndex={1}
                                step={0.009}
                                maxMove={10}
                                opacity={0.8}
                            />
                        )}
                        {isSphereExists && (
                            <Sphere
                                color={'lightBlue'}
                                zIndex={2}
                                step={0.01}
                                maxMove={30}
                                opacity={0.5}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
