import React from 'react';

import styles from './StatCard.module.scss';
import { StatCardProps } from './StatCard.type';

export const StatCard: React.FC<StatCardProps> = ({ count, text, symbol }) => (
    <div className={styles.container}>
        <div className={styles.left}>
            {count}
            {!!symbol && symbol}
        </div>
        <div className={styles.delimiter} />
        <div className={styles.right}>{text}</div>
    </div>
);
