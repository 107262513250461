import React, { Fragment } from 'react';
import { Counter } from '../Counter';
import styles from './Counters.module.scss';

import { CountersProps } from './Counters.type';

export const Counters: React.FC<CountersProps> = ({ data }) => {
    return (
        <div className={styles.container}>
            {data.map((item, key) => (
                <Fragment key={key}>
                    <Counter {...item} />
                    {key + 1 < data.length && (
                        <div className={styles.delimiter} />
                    )}
                </Fragment>
            ))}
        </div>
    );
};
