import React from 'react';
import { numberWithCommas } from '../../../utils';
import styles from './Profit.module.scss';
import { ProfitProps } from './Profit.type';

export const Profit: React.FC<ProfitProps> = ({ price, company, currency }) => (
    <div className={styles.container}>
        <div className={styles.price}>
            {numberWithCommas(price, ' ')} {currency}
        </div>
        <div className={styles.company}>{company}</div>
    </div>
);
