import Link from 'next/link';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { fillArrayOfObjectsWithColors } from '../../../utils/helpers';
import { ChevronRightIcon } from '../../Icons';
import { rootLinks } from '../../Links';
import { NewsCard, NewsCardProps } from '../../_shared/NewsCard';
import styles from './NewsCards.module.scss';
import { NewsCardsProps } from './NewsCards.type';
import { Swiper, SwiperSlide } from 'swiper/react';

export const NewsCards: React.FC<NewsCardsProps> = ({ news }) => {
    const colors: NewsCardProps['item']['color'][] = [
        'orange',
        'green',
        'purple',
        'yellow',
    ];
    const data = fillArrayOfObjectsWithColors(news, colors);
    const isBrowser = typeof window !== 'undefined';
    const [windowWidth, setWindowWidth] = useState(
        isBrowser ? window.innerWidth : 1120
    );

    const handleResize = useCallback(() => {
        setWindowWidth(isBrowser ? window.innerWidth : 1120);
    }, [isBrowser]);
    useEffect(() => {
        return window.addEventListener('resize', handleResize);
    }, [handleResize]);
    const breakpoints = useMemo(() => {
        return {
            // when window width is >= 1120px
            1120: {
                slidesPerView: 4,
                spaceBetween: 24,
            },
        };
    }, []);
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2>Новости</h2>
                <Link href={{ pathname: rootLinks.news.url }}>
                    <a className={styles.link}>
                        <span>показать все</span>
                        <ChevronRightIcon />
                    </a>
                </Link>
            </div>
            <Swiper
                breakpoints={breakpoints}
                className={styles.cards}
                setWrapperSize
                width={windowWidth < 1120 ? 286 : undefined}
            >
                {data.map((item, key) => (
                    <SwiperSlide key={key}>
                        <NewsCard item={item} key={key} isOpacity />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};
