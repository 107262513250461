import clsx from 'clsx';
import Link from 'next/link';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { OrgSection } from '../../../types/entities';
import { Company } from '../../_shared';
import { ChevronRightIcon } from '../../Icons';
import { rootLinks } from '../../Links';
import styles from './Companies.module.scss';
import { chunk } from 'lodash';

export type CompaniesProps = {
    orgSections: OrgSection[];
};

export const Companies: React.FC<CompaniesProps> = ({ orgSections }) => {
    const chunkedOrgSections = useMemo(
        () => chunk(orgSections, 5),
        [orgSections]
    );
    const pagesCount = useMemo(
        () => chunkedOrgSections?.length,
        [chunkedOrgSections]
    );
    const [isActiveNext, setIsActiveNext] = useState(pagesCount > 0);
    const [isActivePrevious, setIsActivePrevious] = useState(false);
    const [currentPageId, setCurrentPageId] = useState(0);
    const [currentOrgSections, setCurrentOrgSections] = useState<OrgSection[]>(
        []
    );
    // Если выбрали следующую страницу, то меняем текущий массив компаний для отображения
    useEffect(() => {
        setCurrentOrgSections(chunkedOrgSections?.[currentPageId]);
    }, [chunkedOrgSections, currentPageId]);
    useEffect(() => {
        if (currentPageId > 0 && !isActivePrevious) {
            setIsActivePrevious(true);
        }
        if (currentPageId === 0 && isActivePrevious) {
            setIsActivePrevious(false);
        }
        if (currentPageId === pagesCount - 1 && isActiveNext) {
            setIsActiveNext(false);
        }
        if (currentPageId < pagesCount - 1 && !isActiveNext) {
            setIsActiveNext(true);
        }
    }, [currentPageId, isActiveNext, isActivePrevious, pagesCount]);
    const handleNext = useCallback(() => {
        if (currentPageId < pagesCount - 1) {
            setCurrentPageId(currentPageId + 1);
        }
    }, [currentPageId, pagesCount]);
    const handlePrevious = useCallback(() => {
        if (currentPageId >= 1) {
            setCurrentPageId(currentPageId - 1);
        }
    }, [currentPageId]);
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Нам доверяют</h2>
            <div className={styles.list}>
                {currentOrgSections?.map((orgSection) => (
                    <div className={styles.item} key={orgSection.id}>
                        <Company orgSection={orgSection} />
                    </div>
                ))}
            </div>
            <div className={styles.pagination}>
                <div
                    className={clsx(
                        styles.btn,
                        styles.left,
                        !isActivePrevious && styles.disabled
                    )}
                    onClick={isActivePrevious ? handlePrevious : undefined}
                >
                    <img src="/assets/main/arrow_left.svg" alt="previous" />
                </div>
                <div
                    className={clsx(
                        styles.btn,
                        styles.right,
                        !isActiveNext && styles.disabled
                    )}
                    onClick={isActiveNext ? handleNext : undefined}
                >
                    <img src="/assets/main/arrow_right.svg" alt="next" />
                </div>
                <Link href={rootLinks.companies.url}>
                    <a className={styles.link}>
                        <span>все заказчики</span>
                        <ChevronRightIcon />
                    </a>
                </Link>
            </div>
        </div>
    );
};
