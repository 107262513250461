import React from 'react';
import { numberWithCommas } from '../../../utils';
import styles from './Counter.module.scss';
import { CounterProps } from './Counter.type';

export const Counter: React.FC<CounterProps> = ({ count, text }) => (
    <div className={styles.container}>
        <div className={styles.count}>{numberWithCommas(count, ' ')}</div>
        <div className={styles.text}>{text}</div>
    </div>
);
