import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { HOC } from '../../../utils';
import { mainProfitsDummy } from '../../_dummyData';
import { ProfitsProps } from './Profits.type';

type InjectedProps = ProfitsProps;

export const withState =
    (): HOC<InjectedProps> => (BaseComponent) => (props) => {
        const [data, setData] = useState<ProfitsProps['data']>([]);
        const [isActiveNext, changeIsActiveNext] =
            useState<ProfitsProps['isActiveNext']>(true);
        const [isActivePrevious, changeIsActivePrevious] =
            useState<ProfitsProps['isActivePrevious']>(false);

        useEffectOnce(() => {
            setData(mainProfitsDummy);
        });

        const injectedProps: InjectedProps = {
            data,
            isActiveNext,
            isActivePrevious,
            handleNext() {
                changeIsActiveNext(!isActiveNext);
            },
            handlePrevious() {
                changeIsActivePrevious(!isActivePrevious);
            },
        };

        return <BaseComponent {...(props as any)} {...injectedProps} />;
    };
