import moment from 'moment';
import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';

import { HOC } from '../../../utils';
import formatMomentDate from '../../../utils/formatMomentDate';
import { mainStatsDummy } from '../../_dummyData';

import { StatCardsProps } from './StatCards.type';

type InjectedProps = StatCardsProps;

export const withState =
    (): HOC<InjectedProps> => (BaseComponent) => (props) => {
        const [data, setData] = useState<StatCardsProps['data']>([]);
        const [description, setDescription] =
            useState<StatCardsProps['description']>('');

        useEffectOnce(() => {
            setData(mainStatsDummy);
            setDescription(
                `по данным на ${formatMomentDate(
                    moment().subtract(1, 'months'),
                    'monthYearLong'
                )} года`
            );
        });

        const injectedProps: InjectedProps = {
            data,
            description,
        };

        return <BaseComponent {...(props as any)} {...injectedProps} />;
    };
