import clsx from 'clsx';
import React from 'react';
import { Profit } from '../Profit';
import styles from './Profits.module.scss';
import { ProfitsProps } from './Profits.type';

export const Profits: React.FC<ProfitsProps> = (props) => {
    const { data, isActiveNext, isActivePrevious, handleNext, handlePrevious } =
        props;

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>
                Выгода <br />
                наших клиентов
            </h2>
            <div className={styles.list}>
                {data.map((item, key) => (
                    <div className={styles.item} key={key}>
                        <Profit {...item} />
                    </div>
                ))}
            </div>
        </div>
    );
};
